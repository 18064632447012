
aside {

    display: inline-block;

    width: 20%;
    vertical-align: top;
    margin-right: 2em;

    padding: 1em;

    @media screen and (max-width: $fullsizebreak) {
            display: none;
    }

}

#partners-data {
    display: none;
}

#partners-visible {
    display: block;
}

.partner-item {
    opacity: 0.0;
    transition: opacity 1.0s ease-in;
    width: 197px;
    height: 75px;

    &.loaded {
        opacity: 1.0;
    }
}
