article {

    display: inline-block;
    width: 65%;

    line-height: 1.5em;
    padding: 0 2em;

    h1, h2, h3, h4, h5, h6 {
        color: $waveblue;
        margin-top: 2.0em;
        margin-bottom: 0.5em;
        &:first-of-type {
            margin-top: 0;
        }
    }

    h2 {
        background: url(/assets/images/arrowhead.png) 0em 0.35em no-repeat;
        padding-left: 16px;
        margin-left: -16px;
    }

    a, a:hover, a:visited {
            font-weight: bold;
            color: darkslategrey;
            text-decoration: none;
            letter-spacing: 0.1em;
    }
    a:hover {
        text-decoration: underline;
    }
    
}

