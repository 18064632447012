@import "variables";
@import "main";
@import "quotebar";

@import "nav";

@import "header";
@import "footer";

@import "partners";
@import "content";
