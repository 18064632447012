footer {

    nav {

        font-size: 0.75em;

        a {
            color: $waveblue !important;
            text-transform: uppercase !important;

            &:hover, &.current {
                color: $wavegrey !important;
                font-weight: bold !important;
            }
        }

        p {
            color: $wavegrey;
        }

    }

}

