html, body {
    margin: 0;
    padding: 0;
    border: none;
}

body {
    color: $wavegrey;
    font-family: Arial, Helvetica, Sans-serif;
    width: $pagefixedwidth;
    margin: auto;

    @media screen and (max-width: $fullsizebreak) {
        width: 80%;
    }
}

a, a:visited, a:hover {
    color: black;
}
