nav {
 
    font-weight: bold;
    text-align: center;
    width: 100%;
    display: block;

    a {
        display: inline-block;
        text-transform: uppercase;

        padding: 1em;
        margin: 0 0.5em;
    
        text-decoration: none;

        @media screen and (max-width: $tinybreak) {
             font-size: 1.0em;
             padding: 0.3em;
             margin: 0.3em;
        }
    }

    p {
        color: white;
        font-style: italic;
        font-weight: normal;
        margin-top: 2em;
        margin-bottom: 0;
    }

}
