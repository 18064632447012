header {

    border-radius: 16px;
    overflow: hidden;
    margin: 20px auto;

    nav{
        background: rgb(237,237,237);
        background: linear-gradient(180deg, rgba(237,237,237,1) 0%, rgba(240,240,240,1) 10%, rgba(207,211,216,1) 58%, rgba(185,187,190,1) 100%);
        font-size: 0.813em;
        font-weight: bold;
        padding: 0;

        a {
            vertical-align: middle;

            &:hover, &.current {
                color: white;
                background: rgb(83,148,189);
                background: linear-gradient(180deg, rgba(83,148,189,1) 0%, rgba(83,148,189,1) 34%, rgba(58,103,159,1) 100%);
            }
        }
        
    }

    .hero {
        $curveradius: 32px;

        width: 100%;
        height: 328px;

        position: relative;

        background-color: $waveblue;
        background-size: cover;

        .slogan {

            @media screen and (max-width: $tinybreak) {
                display: none;
            }

            position: absolute;
            top: 27px;
            left: 162px;
            
            width: 203px;
            font-size: 0.75em;
            color: #555;

            padding: 5px;
            
            background: rgba(255,255,255,0.5);
            border-top-right-radius: $curveradius;
            border-bottom-right-radius: $curveradius;

            &>p {
                margin: 0 0 5px;
            }
        }

    }

    .quotebar {
        border-radius: 0;
        margin: 0;
    }

}
