
.quotebar {
    margin: 11px 0 0;
    // height: 44px;
    padding: 14px;
    border-radius: 8px;
    color: white;

    text-align: center;
    text-shadow: 0 -2px 0 #333;
    font-size: 1.75em;

    vertical-align: middle;

    background: rgb(39,40,41);
    background: linear-gradient(180deg, rgba(39,40,41,1) 0%, rgba(74,77,83,1) 34%, rgba(23,24,24,1) 100%);

    &.longquote {
        font-size: 0.95em;
    }
}
